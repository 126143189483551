import React from 'react';
import {Card, Button} from 'react-bootstrap';

const WideCard= ({card}) => {
  return(
    <Card className="mb-15">
    <Card.Header as="h4">
      <Card.Img className="inline s-size" variant="top" src={card?.back_icon}/>
      <h4 className="inline">{card?.name}</h4>
    </Card.Header>
    <Card.Body>
      <Card.Title>Test Parameters</Card.Title>
      <Card.Text>
        {card?.test_parameters}
      </Card.Text>
      <Card.Title>applications</Card.Title>
      <Card.Text>
        {card?.applications}
      </Card.Text>
    </Card.Body>
  </Card>
  );
}

export default WideCard;
