import React from 'react';
import {Card, Button} from 'react-bootstrap';

const CardBack = ({card}) => {
  return (
    <Card className="mb-50 back">
    <div className="icon-wrapper">
      <Card.Img style={{height: '80px', width: 'auto'}} variant="top" src={card.back_icon}/>
    </div>
    <Card.Body>
      <Card.Title>{card.name}</Card.Title>
      <hr className="hr-style hr-style-center"/>
      <Card.Text>
        {card.desc}
      </Card.Text>
    </Card.Body>
  </Card>
  );
};

export default CardBack;
