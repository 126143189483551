import React, { useState, useEffect } from "react";
import VerticalCard from "../components/card/verticalcard";
import { Container, Row, Col } from "react-bootstrap";
import CustomHero from "../components/customhero";
import bg from "../assets/images/background/pattern2.png";
import brochures from "../assets/data/brochures";
import brochures_two from "../assets/data/brochures_two";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { UilTear } from "@iconscout/react-unicons";
import { UilPump } from "@iconscout/react-unicons";
import axios from "axios";

const Brochure = (props) => {
  const [key, setKey] = useState("oil");
  const [oilBrochures, setOilBrochures] = useState({ oil: [] });
  const [fuelBrochures, setFueldBrochures] = useState({ fuel: [] });

  const getData = async () => {
    await axios
      .get("https://dashboard.acculab.sa/api/brochures/")
      .then((response) => {
        const brochures = response.data;
        setOilBrochures({ oil: brochures.oil });
        setFueldBrochures({ fuel: brochures.fuel });
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Container fluid>
        <Tabs id="controlled-tab-example" transition={true} activeKey={key} onSelect={(k) => setKey(k)} className="center margin-minus width-50 white-bg">
          <Tab
            className="first-item-tab"
            eventKey="oil"
            title={
              <h4>
                <UilTear color="#7dc49e" size="21" className="mt-10"></UilTear> Oil
              </h4>
            }
          >
            {props.children}
            <Row>
              {oilBrochures &&
                oilBrochures.oil.map((item, index) => (
                  <Col md={4}>
                    <VerticalCard link={item.image} title={item.name} desc={item.file} />
                  </Col>
                ))}
            </Row>
          </Tab>
          <Tab
            className="second-item-tab"
            eventKey="fuel"
            title={
              <h4>
                <UilPump color="#7dc49e" size="21" className="mt-10"></UilPump> Fuel
              </h4>
            }
          >
            {props.children}
            <Row>
              {fuelBrochures &&
                fuelBrochures.fuel.map((item, index) => (
                  <Col md={4}>
                    <VerticalCard link={item.img} title={item.title} desc={item.download} />
                  </Col>
                ))}
            </Row>
          </Tab>
        </Tabs>
        <Row className="center">
          <a href="#" class="component__button mt-20">
            Show more
          </a>
        </Row>
      </Container>
    </>
  );
};

export default Brochure;
