const card = [
  {
    id: 1,
    icon : require('../images/icons/diesel.png'),
    main_title : "DIESEL FUEL ANALYSIS ",
    title_1 : "Test Parameters",
    desc_1 : "istillation, Density at 20C, Viscosity at 40C, Flashpoint, Total Contamination, Water Content, Sulphur Content, Cleanliness Level, Particle Count, Bacterial and Fungal Growth",
    title_2 : "Applications",
    desc_2 : "Buses, Construction, Industrial Plant, Light Vehicles, Marine, Mining, Offshore Marine, Power Plant, Trains, Trucking",
  }
]

export default card;
