import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

// import industries from '../../assets/data/industries';
import axios from "axios";

import SwiperCore, { EffectFlip, Navigation, Pagination, Scrollbar, A11y } from "swiper";

const IndustriesSlider = (props) => {
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    axios
      .get("https://dashboard.acculab.sa/api/industries/")
      .then((response) => {
        setIndustries(response.data);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }, []);

  return (
    <Swiper
      modules={[EffectFlip, Navigation, Pagination, Scrollbar, A11y]} // I want to focus here
      spaceBetween={20}
      navigation
      scrollbar={{ draggable: true }}
      loop={true}
      breakpoints={{
        // when window width is >= 640px
        640: {
          width: 640,
          slidesPerView: 2,
        },
        // when window width is >= 768px
        768: {
          width: 768,
          slidesPerView: 3,
        },
      }}
    >
      <Container>
        {industries.map((item) => (
          <SwiperSlide>
            <div className="industry-item center">
              <Row className="center">
                <img src={item.icon} alt="Project" className="slide-image" />
              </Row>
              <Row className="mt-20 mb-20">
                <Col>
                  <h5 className="white-font bold-font">{item.name}</h5>
                </Col>
              </Row>
            </div>
          </SwiperSlide>
        ))}
      </Container>
    </Swiper>
  );
};

export default IndustriesSlider;
