import React from "react";
import { Link } from "react-router-dom";
import NavLink from "./navlink";
import logo from "../../assets/images/logo.png";
import {
  handleDropdown,
  handleMobileDropdown,
  handleSearch,
} from "../../common/navbar";

const Navbar = ({ to, nr }) => {
  const isActive = window.location.pathname === to;
  const className = isActive ? "active" : "";

  return (
    <nav ref={nr} className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="logo">
          <img className="logo" src={logo} alt="ACCULAB logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleMobileDropdown}
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar ">
            <i className="fas fa-bars"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink to="/" text="HOME" />
            </li>
            <li className="nav-item">
              <NavLink to="/about" text="ABOUT" />
            </li>
            <li className="nav-item">
              <NavLink to="/services" text="SERVICES" />
            </li>
            <li className="nav-item">
              <NavLink to="/resources" text="RESOURCES" />
            </li>
            <li className="nav-item">
              <NavLink to="/contact" text="CONTACT" />
            </li>
            <li className="nav-item pt-10">
              <a
                className="nav-link login"
                href="http://www.acculabint-online.com/"
                target={"_blank"}
              >
                CLIENT LOGIN
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
