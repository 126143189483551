import React, { useRef, useEffect } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

const Theme = ({ children }) => {
  const navbarRef = useRef(null);

  useEffect(() => {
    var navbar = navbarRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <>
      <Navbar nr={navbarRef} />
      {children}
      <Footer />
    </>
  );
};

export default Theme;
