import React from "react";
import CustomHero from "../components/customhero";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../components/title";
import Contact from "./contact";

const FullProfile = (props) => {
  return (
    <section id="cert">
      <CustomHero
        minHeight="10vh"
        //backgroundImage={bg}
        backgroundAttachment="scroll"
        overlayColor="#FAFAFA"
        overlayOpacity="0.5"
      >
        <Container fluid>
          <Row>
            <Col md={12}>
              <Row className="mt-30">
                <Title
                  title={props.title}
                  direction="left"
                  style="hr-style"
                  dir=""
                  color="dark-blue-font"
                />
              </Row>
              <Row>
                <p className="justify">{props.desc}</p>
              </Row>
            </Col>
          </Row>
          {props.children}
        </Container>
      </CustomHero>
    </section>
  );
};

export default FullProfile;
