import React from 'react';
import {Card, Button} from 'react-bootstrap';

const CardFront = ({card}) => {
  return(
    <Card className="mb-50 front">
    <Card.Img variant="top" style={{height: '100px', width: 'auto'}} src={card.icon}/>
    <Card.Body>
      <Card.Title>{card.name}</Card.Title>
      <hr className="hr-style hr-style-center"/>
    </Card.Body>
  </Card>
  );
};

export default CardFront;
