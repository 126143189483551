import React from 'react';
import {Card, Button} from 'react-bootstrap';
import { UilImport } from '@iconscout/react-unicons'
const VerticalCard = (props) => {
  return(
    <Card style={{backgroundImage : `linear-gradient(rgba(158, 200, 126, 0.8), rgba(7, 7, 78, 0.9)), url(${props.link})`}} className="mb-15 vertical">
    <Card.Body>
      <Card.Title className="center">{props.title}</Card.Title>
      <hr className="hr-style hr-style-center"/>
      <Card.Text className="center">
        <UilImport size="19" className="mt-10"></UilImport><a style={{color : "white"}} className="mb-10" href={props.desc}> DOWNLOAD</a>
      </Card.Text>
    </Card.Body>
  </Card>
  );
};

export default VerticalCard;
