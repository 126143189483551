const features = [
  {
    id: 1,
    img: require('../images/icons/icon4.png'),
    title: 'SAVE ON MAINTENANCE COSTS',
    desc: 'sampling regularly establishes a baseline of normal wear and provides essential information to determine abnormal wear or contamination. Through this, costly repairs can be avoided',
  },
  {
    id: 2,
    img: require('../images/icons/icon1.png'),
    title: 'ENHANCE EQUIPMENT RELIABILITY',
    desc: 'Equipment life and reliability is improved by ensuring proper lubrication is in use and issues are detected ahead of time.',
  },
  {
    id: 3,
    img: require('../images/icons/icon2.png'),
    title: 'EXTEND LUBRICANT LIFE',
    desc:   "Through monitoring of the lubricant's condition,longer drain intervals can be implemented allowing the extension of its usable life.",
  },
  {
    id: 4,
    img: require('../images/icons/icon3.png'),
    title: 'REDUCE EQUIPMENT DOWNTIME',
    desc: 'By spotting and correcting potential lubrication problems before it become a serious issue, equipment downtime can be avoided.',
  }
]

export default features;
