import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import VerticalCardSecond from "../components/card/verticalcardsecond";
import axios from "axios";

import articles_ from "../assets/data/articles";
const Articles = (props) => {
  const [articles, setArticles] = useState({ articles: [] });

  const getData = async () => {
    await axios
      .get("https://dashboard.acculab.sa/api/articles/")
      .then((response) => {
        setArticles({ articles: response.data });
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container fluid className="pr-0">
      <Row className="mt-20 ">
        {articles &&
          articles.articles.map((item, index) => (
            <Col md={6} className="pr-0 pl-0">
              <VerticalCardSecond icon={item.image} title={item.name} desc={item.short_body} author={item.author} body={item.body} />
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default Articles;
