import React from 'react';
import CustomHero from '../customhero';
import Title from '../title';
import {Container, Row} from 'react-bootstrap';
const Divider = (props) => {
  return(
    <CustomHero
      rounded={props.rounded}
      minHeight="40vh"
      backgroundImage={props.bg}
      patternImage={props.pattern}
      backgroundAttachment="fixed"
      overlayColor="linear-gradient(90deg, rgba(67,185,204,1) 0%, rgba(46,163,121,1) 100%)"
      overlayOpacity="0.6">
      <Container fluid className="mt-10">
        <Row className='justify-content-center'>
          <Title
          title={props.title}
          direction={props.direction}
          style={props.style}
          dir={props.dir}
          color={props.color}
          />

        </Row>
      </Container>
    </CustomHero>
  );
};

export default Divider;
