import React from 'react';
import {Card, Button} from 'react-bootstrap';

const CardBackTwo = ({card}) => {
  return (
  <Card className="mb-15 back">
    <Card.Body>
    <Card.Img className="inline s-size" variant="top" src={card.back_icon}/>
      <Card.Title className="inline">{card.name}</Card.Title>
      <hr className="hr-style ml-20-p"/>
      <Card.Text>
        {card.desc}
      </Card.Text>
    </Card.Body>
  </Card>
  );
};

export default CardBackTwo;
