import React from 'react';


const CustomHero = (props) => {
  const {
   rounded,
   minHeight,
   patternImage,
   backgroundImage,
   backgroundAttachment,
   overlayColor,
   overlayOpacity,
   alignXContent,
   alignYContent,
   contentPadding,
   children
 } = {
   rounded: "",
   minHeight: "150px",
   backgroundImage: "",
   patternImage: "",
   backgroundAttachment: "scroll", //additional properties: "fixed" parallax effect || "scroll" default
   overlayColor: "red",
   overlayOpacity: "0.5",
   alignXContent: "center", //additional properties: flex-start, center, flex-end
   alignYContent: "center", //additional properties: flex-start, center, flex-end
   contentPadding: "40px 40px",
   ...props
 };
    return  (
        <section>
        <div>
   <div //hero image
     style={{
       borderRadius: rounded,
       backgroundImage: `url(` + backgroundImage + `),url(`+ patternImage +`)`,
       backgroundAttachment: backgroundAttachment,
       minHeight: minHeight,
       width: "100%",
       backgroundPosition: "center",
       backgroundSize: "cover",
       backgroundRepeat: "no-repeat",
       position: "relative" //"sticky" || "relative" works
     }}
   >
     <div //overlay
       style={{
         borderRadius: rounded,
         background: overlayColor,
         opacity: overlayOpacity,
         position: "absolute",
         flex: "auto",
         width: "100%",
         height: "100%",
         top: "0",
         left: "0",
         right: "0",
         bottom: "0",
         zIndex: "2"
       }}
     />
     <div //content
       style={{
         minHeight: minHeight,
         padding: contentPadding,
         display: "flex",
         flex: "auto",
         justifyContent: alignXContent,
         alignItems: alignYContent,
         zIndex: "2",
         position: "relative" //"sticky" || "relative" works
       }}
     >
       {children}
     </div>
   </div>
 </div>
        </section>
    )
};


export default CustomHero;
