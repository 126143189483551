const servicestwo = [
  {
    id: 1,
    icon : require('../images/icons/icon6.png'),
    main_title : "ENGINE OIL ANALYSIS",
    title_1 : "Test Parameters",
    desc_1 : "Wear Metals, Cleanliness Level, Contaminants, Water, Additives, Viscosity at 40C, Viscosity at 100C, Total Acid Number (TAN)",
    title_2 : "Applications",
    desc_2 : "Buses, Construction, Industrial Plant, Light Vehicles, Marine, Mining, Offshore Marine, Power Plant, Trains, Trucking",
  },
  {
    id: 2,
    icon : require('../images/icons/gearbox.png'),
    main_title : "GEARBOX OIL ANALYSIS",
    title_1 : "Test Parameters",
    desc_1 : "Wear Metals, Contaminants, Water, Additives, Viscosity at 40C, Viscosity at 100C, Total Base Number (TBN)",
    title_2 : "Applications",
    desc_2 : "Buses, Construction, Industrial Plant, Light Vehicles, Marine, Mining, Offshore Marine, Power Plant, Trains, Trucking",
  },
  {
    id: 3,
    icon : require('../images/icons/hydraulic.png'),
    main_title : "HYDRAOULIC OIL ANALYSIS",
    title_1 : "Test Parameters",
    desc_1 : "Wear Metals, Cleanliness Level, Contaminants, Water, Additives, Viscosity at 40C, Viscosity at 100C, Total Acid Number (TAN)",
    title_2 : "Applications",
    desc_2 : "Buses, Construction, Industrial Plant, Light Vehicles, Marine, Mining, Offshore Marine, Power Plant, Trains, Trucking",
  },
  {
    id: 4,
    icon : require('../images/icons/compressor.png'),
    main_title : "COMPRESSOR OIL ANALYSIS",
    title_1 : "Test Parameters",
    desc_1 : "Wear Metals, Contaminants, Water, Additives, Viscosity at 40C, Viscosity at 100C, Total Base Number (TBN)",
    title_2 : "Applications",
    desc_2 : "Buses, Construction, Industrial Plant, Light Vehicles, Marine, Mining, Offshore Marine, Power Plant, Trains, Trucking",
  },
  {
    id: 5,
    icon : require('../images/icons/turbine.png'),
    main_title : "TURBINE OIL ANALYSIS",
    title_1 : "Test Parameters",
    desc_1 : "Wear Metals, Contaminants, Water, Additives, Viscosity at 40C, Viscosity at 100C, Total ACID Number (TAN), Cleanliness Level, Color, MPC",
    title_2 : "Applications",
    desc_2 : "Industrial Plant, Marine, Mining, Offshore Marine, Power Plant,",
  },
  {
    id: 6,
    icon : require('../images/icons/trans.png'),
    main_title : "TRANSFORMER OIL ANALYSIS",
    title_1 : "Test Parameters",
    desc_1 : "Wear Metals, Contaminants, Water, Additives, Viscosity at 40C, Viscosity at 100C, Total ACID Number (TAN), Cleanliness Level, Breakdown Voltage (dielectric stregth) *No PCB*",
    title_2 : "Applications",
    desc_2 : "Power Plant",
  },
]

export default servicestwo;
