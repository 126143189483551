import React, { useEffect, useState } from "react";
import CustomHero from "../components/customhero";
import { Container, Row, Col } from "react-bootstrap";
import servicesthree from "../assets/data/servicesthree";
import WideCard from "../components/card/widecard";
import axios from "axios";

const ServicesThree = (props) => {
  let [services, setServices] = useState();

  useEffect(() => {
    axios.get("https://dashboard.acculab.sa/api/oil_service/").then((res) => setServices(res.data));
  }, []);

  return (
    <section id="services">
      <CustomHero
        minHeight="100vh"
        //backgroundImage={bg}
        backgroundAttachment="scroll"
        overlayColor="#FAFAFA"
        overlayOpacity="0.1"
      >
        <Container fluid className="mt-10 mb-30">
          <Row className="mb-60">
            {services?.map((card) => (
              <Col className="mb-20" md={6}>
                <WideCard key={card.id} card={card} />
              </Col>
            ))}
          </Row>
        </Container>
      </CustomHero>
    </section>
  );
};

export default ServicesThree;
