import React from "react";
import { Row, Col } from "react-bootstrap";
const Title = (props) => {
  return (
    <Row className="section-heading">
      <Col className="">
        <h2 className={`section-title ${props.direction} ${props.color} `}>
          {props.title}
        </h2>
        <hr className={`${props.style} ${props.dir}`} />
      </Col>
    </Row>
  );
};

export default Title;
