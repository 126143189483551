import React, { useState, useEffect } from "react";
import CustomHero from "../components/customhero";
import Title from "../components/title";
import { Container, Row, Col } from "react-bootstrap";
import bg from "../assets/images/background/pattern.png";
// import services from '../assets/data/services';
import FlipCard from "../components/card/flipcard";

import axios from "axios";

const Services = (props) => {
  const [more, setMore] = useState(false);
  const [services, setServices] = useState([]);

  useEffect(() => {
    axios
      .get("https://dashboard.acculab.sa/api/services/")
      .then((response) => {
        // console.log(response.data);
        setServices(response.data);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }, []);

  function handleClick(e) {
    setMore(true);
  }
  return (
    <section id="features">
      <CustomHero
        minHeight="100vh"
        //backgroundImage={bg}
        backgroundAttachment="scroll"
        overlayColor="#FAFAFA"
        overlayOpacity="0.1"
      >
        <Container fluid className="mt-10 mb-40">
          <Row className="mt-50">
            <Title title="OUR SERVICES" direction="left" style="hr-style" dir="" color="dark-blue-font" />
          </Row>
          <Row>
            <p>We conduct oil and fuel test and analysis with test parameters conforming to international standards. Check out our Services Page for more information.</p>
          </Row>
          <Row>
            {services.map((card, index) =>
              index < 4 ? (
                <Col md={3}>
                  <FlipCard style="0" key={card.id} card={card} />
                </Col>
              ) : null
            )}
          </Row>
          {!more && (
            <Row className="center">
              <a type="button" onClick={handleClick} class="component__button mt-70">
                Show more
              </a>
            </Row>
          )}
          {more && (
            <Row>
              {services.map((card, index) =>
                index >= 4 ? (
                  <Col md={3}>
                    <FlipCard style="0" key={card.id} card={card} />
                  </Col>
                ) : null
              )}
            </Row>
          )}
        </Container>
      </CustomHero>
    </section>
  );
};

export default Services;
