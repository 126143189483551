import React, { useState, useEffect } from "react";
import CustomHero from "../components/customhero";
import { Container, Row, Col } from "react-bootstrap";
// import servicestwo from '../assets/data/servicestwo';
import FlipCard from "../components/card/flipcard";

import axios from "axios";

const ServicesTwo = (props) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    axios
      .get("https://dashboard.acculab.sa/api/services_page/")
      .then((response) => {
        const services = response.data;
        setServices(response.data);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }, []);

  return (
    <section id="services">
      <CustomHero
        minHeight="50vh"
        //backgroundImage={bg}
        backgroundAttachment="scroll"
        overlayColor="#FAFAFA"
        overlayOpacity="0.1"
      >
        <Container fluid className="mt-10 mb-30">
          <Row className="mb-60">
            {services.map((card) => (
              <Col md={4}>
                <FlipCard style="1" key={card.id} card={card} />
              </Col>
            ))}
          </Row>
        </Container>
      </CustomHero>
    </section>
  );
};

export default ServicesTwo;
