import React from 'react';
import CardItem from '../components/card';
import {Container,Row,Col} from 'react-bootstrap';
import CustomHero from '../components/customhero';
import bg from '../assets/images/background/pattern2.png';
import features from '../assets/data/features';
const Features = (props) => {
  return(
    <section id="features">
      <CustomHero
        minHeight="50vh"
        backgroundImage={bg}
        backgroundAttachment="fixed"
        overlayColor="#FAFAFA"
        overlayOpacity="0.1">
        <Container fluid className="mt-10">
          <Row>
          {
            features.map((item,index) => (
              <Col md={3}>
                <CardItem
                link={item.img}
                title={item.title}
                desc={item.desc}/>
              </Col>
            ))
          }
          </Row>
        </Container>
      </CustomHero>
    </section>
  );
}

export default Features;
