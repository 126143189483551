import React from "react";
import { Card, Button } from "react-bootstrap";

const SmallCard = ({ card }) => {
  return (
    <Card className="small mb-50 small-card">
      <Card.Img className="icon" variant="top" src={card.img} />
      <Card.Body className="">
        <Card.Title className="center">
          <a href={`${card.pre}${card.title}`}>{card.title}</a>
        </Card.Title>
      </Card.Body>
    </Card>
  );
};

export default SmallCard;
