import React, { useEffect, useState } from "react";
import CustomHero from "../components/customhero";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../components/title";
import Contact from "./contact";
import WideCard from "../components/card/widecard";
import card from "../assets/data/card";
import axios from "axios";

const Profile = (props) => {
  let [card, setCard] = useState();

  useEffect(() => {
    axios.get("https://dashboard.acculab.sa/api/fuel_service/").then((res) => setCard(res.data[0]));
  }, []);

  return (
    <section id="cert">
      <CustomHero
        minHeight="70vh"
        //backgroundImage={bg}
        backgroundAttachment="scroll"
        overlayColor="#FAFAFA"
        overlayOpacity="0.5"
      >
        <Container fluid>
          <Row>
            {props.style == 1 ? (
              <Col md={6}>
                <Row className="mt-30">
                  <Title title={props.title} direction="left" style="hr-style" dir="" color="dark-blue-font" />
                </Row>
                <Row>
                  <p className="justify">{props.desc}</p>
                </Row>
              </Col>
            ) : (
              <Col md={5}>
                <Row className="mt-30">
                  <Title title={props.title} direction="left" style="hr-style" dir="" color="dark-blue-font" />
                </Row>
                <Row>
                  <p className="justify">{props.desc}</p>
                </Row>
              </Col>
            )}

            {props.style == 1 ? (
              <Col className="mt-10-p" md={{ span: "5", offset: "1" }}>
                {" "}
                <WideCard key={card?.id} card={card} />{" "}
              </Col>
            ) : (
              <Col className="mt-30" md={7}>
                <div className={props.wrapper}>
                  <img className="image-thumb" src={props.img} />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </CustomHero>
    </section>
  );
};

export default Profile;
