import { useState } from "react";
import cn from "classnames";
import CardBack from './cardback';
import CardBackTwo from './cardbacktwo';
import CardFront from './cardfront';

function FlipCard({ card, style }) {
  const [showBack, setShowBack] = useState(false);

  function handleClick() {
    if (card.variant === "click") {
      setShowBack(!showBack);
    }
  }

  return (
      <div
        className={style == 0 ? "big flip-card-outer" : "flip-card-outer"}
        onClick={handleClick}
      >
        <div
          className={cn("flip-card-inner", {
            showBack,
            "hover-trigger": true //card.variant === "hover"
          })}
        >
        <CardFront card={card}/>
        {style == 0 ? <CardBack card={card}/> : <CardBackTwo card={card}/>}
        </div>
      </div>
  );
}

export default FlipCard;
