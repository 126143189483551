import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';

// Import scss Here
import './index.scss';

//Import Pages
import Home from "./pages"
import About from "./pages/about"
import Contact from "./pages/contact"
import Services from "./pages/services"
import Resources from "./pages/resources"

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={'/'}>
      <Routes>
        <Route exact path={`${process.env.PUBLIC_URL}/`} element={<Home/>}/>
        <Route exact path={`${process.env.PUBLIC_URL}/about`} element={<About/>}/>
        <Route exact path={`${process.env.PUBLIC_URL}/contact`} element={<Contact/>}/>
        <Route exact path={`${process.env.PUBLIC_URL}/resources`} element={<Resources/>}/>
        <Route exact path={`${process.env.PUBLIC_URL}/services`} element={<Services/>}/>
      </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
