import React from 'react'
import Theme from '../../layouts/theme';
import Banner from '../../components/banner'
import bannerbg from '../../assets/images/background/banner.jpg'
import pattern from '../../assets/images/background/divider.png'
import {Container, Row, Col} from 'react-bootstrap'
import Title from '../../components/title';
import CustomHero from '../../components/customhero';
import SmallCard from '../../components/card/smallcard';
import contacts from '../../assets/data/contacts';
import Contact from '../../sections/contact';

const ContactPage = (props) => {
  return(
    <Theme>
      <Banner
      bg={pattern}
      pattern={bannerbg}
      title="CONTACT US"
      direction="left"
      style="hr-style"
      dir=""
      color="gradient-font"/>
      <CustomHero
        minHeight="100vh"
        //backgroundImage={bg}
        backgroundAttachment="scroll"
        overlayColor="#FAFAFA"
        overlayOpacity="0.1">
        <Container fluid>
          <Row className="mt-10 mb-30">
            <Title
            title="ACCULAB INTERNATIONAL"
            direction="left"
            style="hr-style"
            dir=""
            color="dark-blue-font"
            />
          </Row>
          <Row>
            <Col className="map">
            <iframe
                  title="Lamar"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14490.354830001968!2d46.766694101818835!3d24.77528720017938!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2413bc92bbb149ed!2sSahab%20Towers!5e0!3m2!1sen!2sde!4v1655317738657!5m2!1sen!2sde"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                ></iframe>
            </Col>
          </Row>
          <Row className="mt--">
            {
              contacts.map((card) =>(
                <Col md={4}>
                  <SmallCard
                  card={card}/>
                </Col>
              ))
            }
          </Row>
            <Contact />
        </Container>
      </CustomHero>
    </Theme>
  );
};

export default ContactPage;
