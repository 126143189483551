import React, { useEffect, useState } from "react";
import Theme from "../../layouts/theme";
import Industries from "../../sections/industries";
import Profile from "../../sections/profile";
import FullProfile from "../../sections/fullprofile";
import DarkDivider from "../../components/divider/darkdivider";
import Banner from "../../components/banner";
import bg from "../../assets/images/background/darkdivider.jpg";
import bannerbg from "../../assets/images/background/banner.jpg";
import pattern from "../../assets/images/background/divider.png";
import certbg from "../../assets/images/background/certbg.png";
import whyusbg from "../../assets/images/background/whyus.png";
import axios from "axios";

const About = (props) => {
  let [aboutUsData, setAboutUsData] = useState();

  useEffect(() => {
    axios
      .get("https://dashboard.acculab.sa/api/about_us/")
      .then((res) => setAboutUsData(res.data[0]))
      .catch((err) => console.log(`Error: ${err}`));
  }, []);

  return (
    <Theme>
      <Banner bg={pattern} pattern={bannerbg} title="ABOUT US" direction="left" style="hr-style" dir="" color="gradient-font" />
      <FullProfile title="WHO WE ARE" desc={aboutUsData?.who_we_are} />
      <Profile title="WHAT WE DO" desc={aboutUsData?.what_we_do} img={aboutUsData?.what_we_do_image} wrapper="wrapper" />
      <DarkDivider bg={pattern} pattern={bg} title={aboutUsData?.divider_text} direction="center" style="hr-style-3" dir="" color="white-font" />
      <Profile title="Certifications" desc={aboutUsData?.certificates_text} img={certbg} wrapper="wrapper-small" />
      <Industries />

      <div style={{ height: "50px" }}></div>
    </Theme>
  );
};

export default About;
