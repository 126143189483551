import React from 'react';
import CustomHero from '../customhero';
import Title from '../title';
import {Container, Row} from 'react-bootstrap';

const DarkDivider = (props) => {
  return(
    <CustomHero
      minHeight="40vh"
      backgroundImage={props.bg}
      patternImage={props.pattern}
      backgroundAttachment="fixed"
      overlayColor="rgba(7,7,78,0.85)"
      overlayOpacity="1">
      <Container fluid className="mt-10">
        <Row>
          <Title
          title={props.title}
          direction={props.direction}
          style={props.style}
          dir={props.dir}
          color={props.color}/>
        </Row>
        <Row className="center">
          <a type="button" class="component__button--secondary mt-20" href='/contact'>Learn More</a>
        </Row>
      </Container>
    </CustomHero>
  );
};

export default DarkDivider;
