import React from "react";
import CustomHero from "../customhero";
import Title from "../title";
import { Container, Row, Col } from "react-bootstrap";
import { UilBookmark } from "@iconscout/react-unicons";
import bookmark from "../../assets/images/icons/bookmark.png";

const DividerSecond = (props) => {
  return (
    <CustomHero
      rounded={props.rounded}
      minHeight="40vh"
      backgroundImage={props.bg}
      patternImage={props.pattern}
      backgroundAttachment="fixed"
      overlayColor="rgba(7,7,78,0.85)"
      overlayOpacity="0.8"
    >
      <Container fluid className="mt-10 pr-0 pl-0">
        <Row>
          <Col md={{ offset: 1, span: 2 }}>
            <img style={{ width: "80%", height: "auto" }} src={bookmark} />
          </Col>
          <Col md={9}>
            <Row>
              <h4 className="white">BY: ACCULAB ADMIN IN NEWS</h4>
            </Row>
            <Row>
              <p className="white">
                ACCULAB INTERNATIONAL is in the process of getting ISO 17025
                accreditation. The entire team is working hard towards a
                successful audit, certification and its implementation. Stay
                tuned to the official post once we get accredited!
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    </CustomHero>
  );
};

export default DividerSecond;
