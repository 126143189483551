import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomHero from "../components/customhero";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../components/title";
import Contact from "./contact";
import whyusbg from "../assets/images/background/whyus.png";

const WhyUs = (props) => {
  let [whyUs, setWhyUs] = useState("");

  useEffect(() => {
    axios
      .get("https://dashboard.acculab.sa/api/home_data/")
      .then((response) => {
        setWhyUs(response.data[0]);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }, []);

  return (
    <section id="whyus">
      <CustomHero
        minHeight="100vh"
        //backgroundImage={bg}
        backgroundAttachment="scroll"
        overlayColor="#FAFAFA"
        overlayOpacity="0.5"
      >
        <Container fluid>
          <Row>
            <Col md={5}>
              <Row className="mt-50">
                <Title title="WHY CHOOSE US" direction="left" style="hr-style" dir="" color="dark-blue-font" />
              </Row>
              <Row>
                <p className="justify">{whyUs.why_choose_us}</p>
              </Row>
            </Col>
            <Col className="mt-50" md={7}>
              <div className="wrapper w-100">
                <img className="image-thumb" alt="img" src={whyusbg} />
              </div>
            </Col>
          </Row>
          <Contact />
        </Container>
      </CustomHero>
    </section>
  );
};

export default WhyUs;
