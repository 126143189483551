import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import photo_1 from "../../assets/images/slider/oil.webp";

const Slider = (props) => {
  // scroll to next section function:
  function scrollDown() {
    let landing = document.querySelector(".home-landing");
    window.scrollTo(0, landing.offsetTop + window.innerHeight - 100);
  }

  return (
    <Container className="home-landing pr-0 pl-0" fluid>
      <img src={photo_1} alt="Project" className="slide-image slider-img" />
      <Row className="content">
        <Col>
          <h2 className="white-font bold-font">ENHANCE YOUR</h2>
          <h1 className="cyan-font bold-font">BOTTOM LINE</h1>
          <h3 className="white-font bold-font">THROUGH ROUTINE OIL ANALYSIS</h3>
          <a href="#" class="component__button--secondary mt-20">
            LEARN MORE
          </a>
        </Col>
      </Row>

      <button className="landing-arrow">
        <FontAwesomeIcon icon={faArrowDown} onClick={scrollDown} />
      </button>
    </Container>
  );
};

export default Slider;
