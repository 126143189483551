import React, { useState, useEffect } from "react";
import CustomHero from "../components/customhero";
import bg from "../assets/images/background/pattern.png";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../components/title";
import IndustriesSlider from "../components/slider/industriesslider";
// import industries from '../assets/data/industries';
import pattern from "../assets/images/background/divider.png";

import axios from "axios";

const Industries = (props) => {
  const [more, setMore] = useState(false);
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    axios
      .get("https://dashboard.acculab.sa/api/industries/")
      .then((response) => {
        const industries = response.data;
        setIndustries(response.data);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }, []);

  function handleClick() {
    setMore(true);
  }
  return (
    <section id="industries">
      <CustomHero minHeight="70vh" patternImage={pattern} backgroundAttachment="scroll" overlayColor="rgba(7, 7, 78, 1)" overlayOpacity="1">
        <Container fluid className="mt-10">
          <Title title="INDUSTRIES WE SERVE" direction="center" style="hr-style hr-style-center" dir="" color="white-font" />
          <Row className="center white-font">
            <p>Our services are applicable to all kinds of industries.</p>
          </Row>
          {!more && (
            <Row className="mt-50">
              <IndustriesSlider />
            </Row>
          )}
          {/* {!more && <Row className="center">
           <a type="button"  onClick={handleClick} class="component__button--secondary mt-70">Show more</a>
          </Row>} */}
          {/* {more && <Row className="mt-20 center">
            {industries.map((item,index) => (
              <Col md={3} xs={6}>
                <div className="industry-item center">
                  <Row className="center">
                  <img
                    src={item.icon}
                    alt="Project"
                    className="slide-image"
                  />
                  </Row>
                  <Row className="mt-20 mb-20">
                   <Col>
                     <h5 className="white-font bold-font">{item.name}</h5>
                   </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>} */}
        </Container>
      </CustomHero>
    </section>
  );
};

export default Industries;
