import React from 'react';
import {Row,Col} from 'react-bootstrap';
const TitleSmall = (props) => {
  return(
    <Row>
      <Col>
        <h3 className={`${props.direction} ${props.color}`}>{props.title}</h3>
      </Col>
    </Row>
  );
};

export default TitleSmall;
