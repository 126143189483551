import React, { useState } from "react";
import Theme from "../../layouts/theme";
import Banner from "../../components/banner";
import FullProfile from "../../sections/fullprofile";
import FullProfileSmall from "../../sections/fullprofilesmall";
import Brochure from "../../sections/brochures";
import Articles from "../../sections/articles";
import Divider from "../../components/divider";
import DividerSecond from "../../components/divider/dividersecond";
import bannerbg from "../../assets/images/background/banner_2.jpg";
import bannerbg_ from "../../assets/images/background/banner_art.jpg";
import pattern from "../../assets/images/background/divider.png";
import bg from "../../assets/images/background/stats.jpg";

const Resources = (props) => {
  const [toggleClassName, setClassName] = useState("tab");

  function onClick() {
    if (toggleClassName.includes("active")) {
      setClassName("");
    } else {
      setClassName("active");
    }
  }
  return (
    <Theme>
      <Banner
        bg={pattern}
        pattern={bannerbg}
        title="BROCHURES, FORMS AND ARTICLES"
        direction="left"
        style="hr-style"
        dir=""
        color="gradient-font"
      />
      <Brochure>
        <FullProfileSmall
          title="BROCHURES AND OTHER HELPFUL INFORMATION"
          desc="Our brochures, forms and sampling instructions are available below for download in PDF format. If you would like to know further about our services or ask for quotations, please do not hesitate to contact us via email at info@acculab.sa."
        />
      </Brochure>
      <FullProfile
        title="NEWS AND ARTICLES"
        desc="Related to ACCULAB, oil and fuel analysis."
      >
        <Articles />
        <DividerSecond
          rounded="30px"
          bg={pattern}
          pattern={bannerbg_}
          title="ACCURATE REPORTS KEEP ASSETS PROTECTED"
          direction="center"
          style="hr-style-2"
          dir=""
          color="white-font"
        />
      </FullProfile>
    </Theme>
  );
};

export default Resources;
