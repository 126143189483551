import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {UilLocationPoint, UilMobileAndroid, UilEnvelope, UilGlobe} from '@iconscout/react-unicons';
import logo from "../../assets/images/logo.png";

const Footer = (props) => {
  return(
    <div>
      <footer className="pb-50 clearfix bg-footer">
        <Container>
          <Row>
            <Col md={6}>
              <img className="logo" src={logo} alt="ACCULAB logo" />
              <p className="mt-40">ACCULAB International offers oil and fuel testing services through state of the art laboratory equipment and high quality tribology services. We provide the most accurate oil and fuel analysis reports in the kingdom. We serve all kinds of clients from all types of industries with full competence in our capability as the most trusted fluid testing laboratory and result analysis</p>
            </Col>
            <Col md={3}>
              <h6 className="title">CONTACT INFO</h6>
              <a><UilLocationPoint size="22" color="#43B9CC" /> Office 11, Sahab Towers, King Abdullah
                                                                Street, Riyadh, Kingdom of Saudi Arabia</a>

              <a href="tel:+966 11 416 9048"><UilMobileAndroid size="22" color="#43B9CC" /> +966 11 416 9048</a>

              <a href="mailto:info@acculab.sa"><UilEnvelope size="22" color="#43B9CC" /> info@acculab.sa</a>

              <a  href="https://www.acculab.sa"><UilGlobe size="22" color="#43B9CC" /> www.acculab.sa</a>
            </Col>
            <Col md={3}>
              <h6 className="title">USEFUL LINKS</h6>
              <a href='#'>Oil Analysis Brochure</a>
              <a href='#'>Fuel Analysis Brochure</a>
              <a href='#'>How To Take Oil Samples</a>
              <a href='#'>How To Complete Sample Form</a>
              <a href='#'>How To Read Reports</a>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
