const contacts = [
  {
    id: 1,
    img: require("../images/icons/call.png"),
    title: "+966 11 416 9048",
    pre: "tel:",
  },
  {
    id: 2,
    img: require("../images/icons/world.png"),
    title: "www.acculab.sa",
    pre: "",
  },
  {
    id: 3,
    img: require("../images/icons/message.png"),
    title: "info@acculab.sa",
    pre: "mailto:",
  },
];

export default contacts;
