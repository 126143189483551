import React from 'react';

const NavLink = ({to, text}) => {
  const isActive = window.location.pathname === to;
  const className = isActive ? 'active' : '';
  return(
    <a href={to} className={`nav-link ${className}`}>{text}</a>
  );
};

export default NavLink;
