import React from 'react';
import {Card, Button} from 'react-bootstrap';
const CardItem = (props) => {
  return(
    <Card className="mb-15">
    <Card.Img variant="top" src={props.link}/>
    <Card.Body>
      <Card.Title>{props.title}</Card.Title>
      <hr className="hr-style"/>
      <Card.Text>
        {props.desc}
      </Card.Text>
    </Card.Body>
  </Card>
  );
}

export default CardItem;
