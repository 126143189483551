import React, {useState} from 'react';
import {Card, Button} from 'react-bootstrap';
import { UilImport } from '@iconscout/react-unicons'
import convertHtmlToReact from '@hedgedoc/html-to-react';

const VerticalCardSecond = (props) => {
  const [more, setMore] = useState(false);


  return(
    <Card className="mt-50 mb-50 bigv">
    <div className="icon-wrapper">
      <Card.Img variant="top" src={props.icon}/>
    </div>
    <Card.Body>
      <Card.Title className="center mb-20">{props.title}</Card.Title>
      <Card.Text>
        BY: {props.author}
      </Card.Text>
      {!more && <Card.Text>
        {props.desc}
      </Card.Text>}
      {more && <Card.Text>
        <div className="small">{ convertHtmlToReact (props.body, true) }</div>
      </Card.Text>}
      {!more && <Card.Text className="bottom center">
        <a onClick={(e) => setMore(true)} class="component__button mt-20">Show more</a>
      </Card.Text> }
      {more && <Card.Text className="bottom center">
        <a onClick={(e) => setMore(false)} class="component__button mt-20">Show less</a>
      </Card.Text> }
    </Card.Body>
  </Card>
  );
};

export default VerticalCardSecond;
