const brochures = [
  {
    id: 1,
    img: require('../images/background/bro_1.jpg'),
    title: 'ACCULAB OIL ANALYSIS BROCHURE',
    download: 'sampling regularly establishes a baseline of normal wear and provides essential information to determine abnormal wear or contamination. Through this, costly repairs can be avoided',
  },
  {
    id: 2,
    img: require('../images/background/bro_1.jpg'),
    title: 'ACCULAB OIL SAMPLE FORM',
    download: 'Equipment life and reliability is improved by ensuring proper lubrication is in use and issues are detected ahead of time.',
  },
  {
    id: 3,
    img: require('../images/background/bro_1.jpg'),
    title: 'HOW TO TAKE AN OIL SAMPLE',
    download:   "Through monitoring of the lubricant's condition,longer drain intervals can be implemented allowing the extension of its usable life.",
  }
]

export default brochures;
