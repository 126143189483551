const articles = [
  {
    id: 1,
    img: require('../images/icons/art_1.png'),
    author: "MACHINERY LUBRICATION IN ARTICLES",
    title: 'How to Avoid Machine Failures with Routine Oil Analysis',
    desc: 'A routine oil analysis program can be the best early indicator of a future problem with your machinery. This predictive tool should include sampling all critical equipment at the appropriate intervals. The measure of success will be in the number of issues detected by laboratory tests. Having no issues with your lubricated equipment or oil condition is almost impossible, but striving to minimize watch and warning alarms on lab reports can help your maintenance team focus on tasks that lead to increased reliability.',
    link: "google.com",
  },
  {
    id: 2,
    img: require('../images/icons/art_2.png'),
    author: "MACHINERY LUBRICATION IN ARTICLES",
    title: 'Lubrication Program Enables Simmons Feed to Cut Downtime by 50 Percent',
    desc: 'The Simmons Feed Ingredients plant in Southwest City, Missouri, produces highquality animal nutrition for pet food, aquaculture and livestock. Five years ago, the facility was experiencing one equipment failure after another, with some costing in excess of $200,000. The ensuing reactive maintenance work resulted in parts being ordered for machines that broke down a day earlier and work orders being generated to fix the "Band-Aided" equipment during the next scheduled downtime.',
    link: "google.com",
  },
  {
    id: 3,
    img: require('../images/icons/art_3.png'),
    author: "MACHINERY LUBRICATION IN ARTICLES",
    title: 'How to Verify System Cleanliness After an Oil Flush',
    desc:   "When performing an oil flush on your equipment, it is important to have a plan and procedures that take into account the criteria and materials used for deeming the system clean. This includes particles you cannot see with a naked eye. The width of an average human hair is approximately 40 microns, but particles as small as 2 microns can damage your system. How can you judge your system clean if you cannot see the material? There are several options.",
    link: "google.com",
  }
]

export default articles;
