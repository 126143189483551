import React from "react";
import Theme from "../../layouts/theme";
import Banner from "../../components/banner";
import bannerbg from "../../assets/images/background/banner.jpg";
import pattern from "../../assets/images/background/divider.png";
import ServicesTwo from "../../sections/servicestwo";
import ServicesThree from "../../sections/servicesthree";
import Contact from "../../sections/contact";
import { Container } from "react-bootstrap";
import CustomHero from "../../components/customhero";
import Divider from "../../components/divider";
import FullProfile from "../../sections/fullprofile";
import Profile from "../../sections/profile";
import bg from "../../assets/images/background/stats.jpg";
import servicesthree from "../../assets/data/servicesthree";

const Services = (props) => {
  return (
    <Theme>
      <Banner
        bg={pattern}
        pattern={bannerbg}
        title="SERVICES"
        direction="left"
        style="hr-style"
        dir=""
        color="gradient-font"
      />
      <ServicesTwo />
      <FullProfile
        title="OIL ANALYSIS"
        desc="Oil analysis is defined as the laboratory analysis of a lubricant’s properties, suspended contaminants, and wear debris. Oil analysis is performed during routine predictive maintenance to provide meaningful and accurate information on lubricant and machine conditions. It involves sampling and analyzing oil for various properties and materials that indicate wear and contamination in equipment. A regularly scheduled oil test is key to maximizing the benefits of oil analysis. Sampling regularly establishes a baseline of normal wear and provides essential information to determine abnormal wear or contamination. Through this, costly repairs can be avoided. It is also the most effective and cheapest way to prolong the useful life of lubricants. Our Oil Analysis Packages conform to ASTM test methods. Please check our Oil Analysis Brochure for the complete list of tests and standards."
      />
      <ServicesThree />
      <Divider
        bg={pattern}
        pattern={bg}
        title="THROUGH OIL ANALYSIS, EARLY DETECTION OF POTENTIAL PROBLEMS IS POSSIBLE"
        direction="center"
        style="hr-style-2"
        dir=""
        color="white-font"
      />
      <Profile
        title="FUEL ANALYSIS"
        desc="Testing fuel verifies its condition as well as the equipment’s just like in oil analysis. It makes sure that the fuel has properties at acceptable levels that allow the equipment to operate at optimum capacity. Fuel contamination and degradation can significantly deteriorate equipment performance, block filters, wear down injectors and eventually cause the equipment to fail. Fuel analysis identifies deficiencies, determines their root cause, and suggests appropriate actions. To maximize the benefits of fuel analysis, it is always best to take samples from “new” fuel coming from a new delivery, day tank, or storage tank where contaminants are picked up so we can identify any problems before the fuel goes to the equipment and affect its reliability. Our Fuel Analysis Packages conform to ASTM test methods. Please check our Fuel Analysis Brochure for the full list of tests and standards."
        style="1"
        wrapper="wrapper"
      />
      <CustomHero
        minHeight="70vh"
        //backgroundImage={bg}
        backgroundAttachment="scroll"
        overlayColor="#FAFAFA"
        overlayOpacity="0.1"
      >
        <Container fluid>
          <Contact />
        </Container>
      </CustomHero>
    </Theme>
  );
};

export default Services;
