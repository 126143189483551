import React from 'react'
import Theme from '../layouts/theme'
import Slider from '../components/slider'
import Divider from '../components/divider'
import Features from '../sections/features'
import Services from '../sections/services'
import Industries from '../sections/industries'
import WhyUs from '../sections/whyus'
import bg from '../assets/images/background/stats.jpg'
import pattern from '../assets/images/background/divider.png'

const Home = (props) => {
  return(
    <Theme>
      <Slider />
      <Features />
      <Divider
      bg={pattern}
      pattern={bg}
      title="ACCURATE REPORTS KEEP ASSETS PROTECTED"
      direction="center"
      style="hr-style-2"
      dir=""
      color="white-font"
      
      />
      <Services />
      <Industries />
      <WhyUs />
    </Theme>
  );
};

export default Home;
