import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Title from '../components/title';

const Contact = (props) => {
  return(
    <>
    <Row className="mt-50">
      <Title
      title="SEND US A MESSAGE"
      direction="left"
      style="hr-style"
      dir=""
      color="dark-blue-font"
      />
    </Row>
    <form className="mt-40">
    <Row>
      <Col md={6}>
        <Row>
          <Col md={6}>
            <input type="text" id="fname" name="fname" placeholder="Enter Name"/>
          </Col>
          <Col md={6}>
            <input type="text" id="fcompany" name="fcompany" placeholder="Enter Company"/>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <input type="email" id="femail" name="femail" placeholder="Enter Email"/>
          </Col>
          <Col md={6}>
            <input type="tel" id="fphone" name="fphone" placeholder="Enter Phone"/>
          </Col>
        </Row>
      </Col>
      <Col md={6}>
        <textarea placeholder="Enter your message" id="w3review" name="w3review" rows="5" cols="50">

        </textarea>
        <a type="submit" class="right component__button--secondary mt-30">Submit</a>
      </Col>
    </Row>
    </form>
    </>
  );
};
export default Contact;
