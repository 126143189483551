import React from "react";
import CustomHero from "../customhero";
import Title from "../title";
import { Container, Row } from "react-bootstrap";

const Banner = (props) => {
  return (
    <CustomHero
      minHeight="45vh"
      backgroundImage={props.bg}
      patternImage={props.pattern}
      backgroundAttachment="fixed"
      overlayColor="rgba(7,7,78,0.85)"
      overlayOpacity="1"
    >
      <Container fluid className="mt-10">
        <Row className="left-bottom">
          <Title
            title={props.title}
            direction={props.direction}
            style={props.style}
            dir={props.dir}
            color={props.color}
          />
        </Row>
      </Container>
    </CustomHero>
  );
};

export default Banner;
